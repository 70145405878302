import { type SEOHandle } from '@nasa-gcn/remix-seo'
import { json, type LoaderFunctionArgs } from '@remix-run/node'
import { Link, Outlet } from '@remix-run/react'
import { buttonVariants } from '#app/components/ui/button.tsx'
import { Logo } from '#app/components/ui/logo.tsx'
import { UserDropdown } from '#app/components/user-dropdown.tsx'
import { getUser } from '#app/utils/auth.server.ts'
import { cn } from '#app/utils/misc.tsx'
import {
	checkIsOrgUser,
	checkIsSuperAdmin,
} from '#app/utils/permissions.server.ts'

export const handle: SEOHandle = {
	getSitemapEntries: () => null,
}

export async function loader({ request }: LoaderFunctionArgs) {
	const user = await getUser(request)
	let isSuperAdmin = false
	let isOrgUser = false

	if (user) {
		isSuperAdmin = checkIsSuperAdmin(user)
		isOrgUser = checkIsOrgUser(user)
	}

	const isAdmin = isSuperAdmin || isOrgUser

	return json({ user, isAdmin })
}

export default function Layout() {
	return (
		<>
			<div className="flex h-screen flex-col justify-between">
				<header className="container h-20 py-6">
					<nav className="flex h-8 flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
						<Logo singleLine />
						<div className="flex items-center gap-10">
							<Link
								to="/hotels/list"
								prefetch="intent"
								className={cn(
									buttonVariants({
										variant: 'link',
										size: 'link',
									}),
									'hidden h-8 no-underline md:flex',
								)}
							>
								List your hotel
							</Link>
							<Link
								to="/reservation"
								prefetch="intent"
								className={cn(
									buttonVariants({
										variant: 'link',
										size: 'link',
									}),
									'hidden h-8 no-underline md:flex',
								)}
							>
								Find your reservation
							</Link>
							<UserDropdown />
						</div>
					</nav>
				</header>

				<div className="flex-1">
					<Outlet />
				</div>

				<Footer />
			</div>
		</>
	)
}

function Footer() {
	const year = new Date().getFullYear()
	return (
		<footer className="bg-background">
			<div className="mx-auto max-w-7xl overflow-hidden px-6 pb-10 pt-20 sm:pb-12 sm:pt-24 lg:px-8">
				{/* <nav
					aria-label="Footer"
					className="-mb-6 flex flex-wrap justify-center gap-x-12 gap-y-3 text-sm/6"
				>
					{navigation.main.map((item) => (
						<a
							key={item.name}
							href={item.href}
							className="text-foreground hover:text-foreground/75"
						>
							{item.name}
						</a>
					))}
				</nav>
				<div className="mt-16 flex justify-center gap-x-10">
					{navigation.social.map((item) => (
						<a
							key={item.name}
							href={item.href}
							className="text-foreground hover:text-foreground/75"
						>
							<span className="sr-only">{item.name}</span>
							<item.icon aria-hidden="true" className="h-6 w-6" />
						</a>
					))}
				</div> */}
				<p className="mt-10 text-center text-sm/6 text-foreground/50">
					&copy; {year} Maldives Booking. All rights reserved.
				</p>
			</div>
		</footer>
	)
}
